import React, { useEffect, useState } from 'react';

const VideoAskWidget = ({ video_ask_url }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { // Adjust the scroll threshold as needed
        setIsScrolled(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    const mediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the max-width for your mobile breakpoint

    if (mediaQuery.matches) {
      // Only add the scroll listener if on a mobile device
      window.addEventListener('scroll', handleScroll);
    } else {
      // Directly set isScrolled to true for non-mobile devices
      setIsScrolled(true);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isScrolled) return;

    const removeVideoAskButton = () => {
      const videoAskButton = document.querySelector('.videoask-embed__button--SgLKO');
      if (videoAskButton) {
        videoAskButton.parentNode.removeChild(videoAskButton);
      }
    };

    const removeVideoAskModalWrapper = () => {
      const videoAskModalWrapper = document.getElementById('videoaskModalWrapper');
      if (videoAskModalWrapper) {
        videoAskModalWrapper.parentNode.removeChild(videoAskModalWrapper);
      }
    };

    const removeExistingWidget = () => {
      const widgetIframe = document.querySelector('iframe[src*="videoask.com"]');
      if (widgetIframe) {
        widgetIframe.parentNode.removeChild(widgetIframe);
      }
    };

    const updateStyles = () => {
      const widgetButton = document.querySelector('.videoask-embed__button--SgLKO');
      if (widgetButton) {
        widgetButton.style.setProperty('z-index', '1', 'important');
        
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        const cookieConsentElement = document.querySelector('.CookieConsent');
        
        if (mediaQuery.matches && cookieConsentElement) {
          // Add margin-bottom only on mobile view if the specified element exists
          widgetButton.style.setProperty('bottom', '170px', 'important'); // Adjust the value as needed
          widgetButton.style.setProperty('left', '15px', 'important'); // Adjust the value as needed
        } else {
          // Remove mobile specific styles
          widgetButton.style.removeProperty('bottom');
          // widgetButton.style.removeProperty('left');
        }
      } else {
        // Retry after a short delay if the button isn't found yet
        setTimeout(updateStyles, 100);
      }
    };

    // Remove any existing widget iframe on load
    removeExistingWidget();
    removeVideoAskButton();
    removeVideoAskModalWrapper();

    if (video_ask_url) {
      // Define the VIDEOASK_EMBED_CONFIG globally
      window.VIDEOASK_EMBED_CONFIG = {
        kind: "widget",
        url: video_ask_url,
        options: {
          widgetType: "VideoThumbnailWindowTall",
          text: "",
          backgroundColor: "#27374F",
          position: "bottom-left",
          dismissible: true,
          videoPosition: "center top"
        }
      };

      // Create a script element for the embed.js
      const scriptEmbed = document.createElement('script');
      scriptEmbed.src = 'https://www.videoask.com/embed/embed.js';
      scriptEmbed.async = true;

      // Append the script to the document body
      document.body.appendChild(scriptEmbed);

      // Call updateStyles after the widget is loaded
      scriptEmbed.onload = updateStyles;

      // Monitor the presence of the .CookieConsent element and update styles accordingly
      const observer = new MutationObserver(updateStyles);
      observer.observe(document.body, { childList: true, subtree: true });

      // Add click event listener to the accept button
      const handleAcceptButtonClick = () => {
        const widgetButton = document.querySelector('.videoask-embed__button--SgLKO');
        if (widgetButton) {
          widgetButton.style.removeProperty('bottom');
        }
      };

      const acceptButton = document.getElementById('rcc-confirm-button');
      if (acceptButton) {
        acceptButton.addEventListener('click', handleAcceptButtonClick);
      }

      // Cleanup function to remove the script, widget, and event listener when the component unmounts
      return () => {
        document.body.removeChild(scriptEmbed);
        delete window.VIDEOASK_EMBED_CONFIG;

        // Remove the widget iframe if it exists
        removeExistingWidget();
        removeVideoAskButton();
        removeVideoAskModalWrapper();

        // Disconnect the observer
        observer.disconnect();

        // Remove click event listener from the accept button
        if (acceptButton) {
          acceptButton.removeEventListener('click', handleAcceptButtonClick);
        }
      };
    }
  }, [isScrolled, video_ask_url]); // Effect depends on isScrolled and video_ask_url

  return null;
};

export default VideoAskWidget;
