import React, { useRef } from "react"
import { Modal } from "react-bootstrap"
import "./PlayVideo.scss"
import loadable from "@loadable/component"
import getVideoId from "get-video-id"

const ReactPlayer = loadable(() => import("react-player"))
const { isIOS, isMobile } = loadable(() => import("react-device-detect"))

const VideoModal = ({ embedURL, videoUrl, isOpen, setIsOpen }) => {
  const vidRef = useRef(null)
  const fullscreen = true

  const [isLoading, setIsLoading] = React.useState(true)

  let formattedVideoURL = ""

  const videoid = videoUrl && getVideoId(videoUrl)
  if (videoid) {
    formattedVideoURL = `https://www.youtube-nocookie.com/embed/${videoid.id}`
  }

  if (!(videoUrl || embedURL)) return null

  let ModalContent = null

  if (videoUrl) {
    ModalContent = (
      <ReactPlayer
        onEnded={() => {
          setIsOpen(false)
        }}
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowFullScreen
        ref={vidRef}
        url={formattedVideoURL}
        modestbranding="1"
        controls
        autoPlay
        muted={!!(isMobile && isIOS)}
        playsinline
        playing={isOpen}
        width="100%"
        height="100%"
      />
    )
  }

  if (embedURL) {
    ModalContent = (
      <>
        {isLoading && (
          <div className="video-loader">
            <div className="spinner-border" role="status" />
          </div>
        )}
        <iframe
          title="video"
          src={embedURL}
          width="100%"
          height="100%"
          onLoad={() => setIsLoading(false)}
        />
      </>
    )
  }

  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      dialogClassName="modal-fullscreen modal-video"
      aria-labelledby="example-custom-modal-styling-title"
      backdropClassName="video-backdrop"
      fullscreen={fullscreen}
    >
      <Modal.Header closeButton />
      <Modal.Body>{ModalContent}</Modal.Body>
    </Modal>
  )
}

export default VideoModal
